import React from "react";
import tutorial from "../../assets/tutorial.gif";

const Tutorial = () => (
  <>
    <img
      src={tutorial}
      alt="tutorial"
      style={{
        width: "100%",
        borderRadius: 10
      }}
    />
  </>
);

export default Tutorial;
